export const defaultLocale = 'en'
export const locales = [defaultLocale, 'de', 'fr', 'uk', 'ru'] as const

export type Locale = typeof locales[number]

export type LanguageNames = {
    [K in Locale]: string;
  };

export const languageNames = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    uk: 'Українська',
    ru: 'Русский',
}

